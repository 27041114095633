exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dieta-bez-glutenu-js": () => import("./../../../src/pages/dieta-bez-glutenu.js" /* webpackChunkName: "component---src-pages-dieta-bez-glutenu-js" */),
  "component---src-pages-dieta-bez-laktozy-js": () => import("./../../../src/pages/dieta-bez-laktozy.js" /* webpackChunkName: "component---src-pages-dieta-bez-laktozy-js" */),
  "component---src-pages-dieta-niski-ig-js": () => import("./../../../src/pages/dieta-niski-ig.js" /* webpackChunkName: "component---src-pages-dieta-niski-ig-js" */),
  "component---src-pages-dieta-wege-ryby-js": () => import("./../../../src/pages/dieta-wege-ryby.js" /* webpackChunkName: "component---src-pages-dieta-wege-ryby-js" */),
  "component---src-pages-dostawa-js": () => import("./../../../src/pages/dostawa.js" /* webpackChunkName: "component---src-pages-dostawa-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasz-dietetyk-js": () => import("./../../../src/pages/nasz-dietetyk.js" /* webpackChunkName: "component---src-pages-nasz-dietetyk-js" */),
  "component---src-pages-plan-optimal-js": () => import("./../../../src/pages/plan-optimal.js" /* webpackChunkName: "component---src-pages-plan-optimal-js" */),
  "component---src-pages-plan-sport-js": () => import("./../../../src/pages/plan-sport.js" /* webpackChunkName: "component---src-pages-plan-sport-js" */),
  "component---src-pages-plan-wege-js": () => import("./../../../src/pages/plan-wege.js" /* webpackChunkName: "component---src-pages-plan-wege-js" */),
  "component---src-pages-promocje-js": () => import("./../../../src/pages/promocje.js" /* webpackChunkName: "component---src-pages-promocje-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-zamowienia-js": () => import("./../../../src/pages/zamowienia.js" /* webpackChunkName: "component---src-pages-zamowienia-js" */),
  "component---src-pages-zamowieniefail-js": () => import("./../../../src/pages/zamowieniefail.js" /* webpackChunkName: "component---src-pages-zamowieniefail-js" */),
  "component---src-pages-zamowienieok-js": () => import("./../../../src/pages/zamowienieok.js" /* webpackChunkName: "component---src-pages-zamowienieok-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

